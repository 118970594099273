import React, { useContext, useState, useRef,useEffect } from "react";
import { BrowserContext } from "../services/BrowserContext";
import { Modal } from "bootstrap";

const Login = ()=>{
    const {itemsManipulator} = useContext(BrowserContext);

    const [name,setName] = useState('') ;
    const handleName = (e) => setName(e.target.value);

    const [password,setPassword] = useState('') ;
    const handlePassword = (e) => setPassword(e.target.value);

    const save = () => {
        itemsManipulator.login(name,password);
        setPassword('');
    }
    
    const loginModal = useRef(null);
    const submit = (e) => {
        if (e.key === 'Enter') {
            const modal = Modal.getInstance(loginModal.current, {});
            modal.hide();
            save();
        }
    }
    useEffect(() => {
        const modal = new Modal(loginModal.current);
        itemsManipulator.loginModal = modal;
    },[]);

    return <div className="modal fade" id="loginModal" ref={loginModal} tabIndex="-1" aria-labelledby="modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Login</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input id="username" value={name} onChange={handleName} type="text" className="form-control"></input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input id="password" value={password} onChange={handlePassword} onKeyUp={submit} type="password" className="form-control"></input>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button onClick={save} type="button" className="btn btn-primary" data-bs-dismiss="modal">Login</button>
                    </div>
                </div>
        </div>
    </div>
}
export default Login