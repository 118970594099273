import React from "react";
import Folder from "./Folder";
import File from "./File";

const Content = ({elements,path})=>{
    let currentFolder = elements;
    path.forEach(folderName => currentFolder = currentFolder.child.find( element => element.name === folderName && element.type === 'folder'));
    if(currentFolder){
        currentFolder.child.sort(function(a,b){
            if(a.type === 'folder' && b.type === 'file'){
                return -1;
            }
            if(b.type === 'folder' && a.type === 'file'){
                return 1;
            }
            return a.name.localeCompare(b.name);
        });
    }else{
        return '';
    }
    

    return currentFolder.child.map(function (element) {
        if (element.type === 'folder') {
            return <Folder key={path+'/'+element.name} path={path} name={element.name}/>
        }
        return <File key={path+'/'+element.name} path={path} name={element.name} size={element.size}/>
    });
}
export default Content