import React, { useContext } from "react";
import { BrowserContext } from "../services/BrowserContext";

const Rename = (props)=>{
    const {itemsManipulator} = useContext(BrowserContext);
    const {handleSelectedItemName} = useContext(BrowserContext);
   
    const handleName = function(e){
        handleSelectedItemName(e.target.value);
    }

    const save = function (e) {
        itemsManipulator.rename(props.name);
    }

    return <div className="modal fade" id="renameFolderModal" tabIndex="-1" aria-labelledby="modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">New Name</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <input onChange={handleName} type="text" value={props.name} className="form-control"></input>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button onClick={save} type="button" className="btn btn-primary" data-bs-dismiss="modal">Save</button>
                </div>
            </div>
        </div>
    </div>
}
export default Rename