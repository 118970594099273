import React, { useContext } from "react";
import { BrowserContext } from "./services/BrowserContext";
import ContentActionMenu from "./ContentActionMenu";

const Folder = (props)=>{
    const {handleCurrentPath} = useContext(BrowserContext);
    let path = Array.from(props.path);
    path.push(props.name);
    return <button className="list-group-item list-group-item-action justify-content-between d-flex align-items-center" onDoubleClick={(e)=> handleCurrentPath(path)}>
        <div className="d-flex align-items-center">
            <i className="bi bi-folder-fill text-warning"></i>
            <div>{props.name}</div>
        </div>
        <ContentActionMenu name={props.name} path={props.path}/>
    </button>
}
export default Folder