import React from "react";

const PlexRefreshButton = ({server,port,library,token}) => {

    function refresh() {
        fetch("https://plex.tv/api/resources?includeHttps=1&X-Plex-Token="+token)
        .then((response) => {
            if(response.ok){
                //alert("Refresh of plex library successfully launched");
                return response.text();
            }else{
                alert("Fail to refresh plex library");
            }
        })
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then(data => {
            const device = data.getElementsByName(server)[0];
            const connections = device.getElementsByTagName("Connection");
            const url = connections[connections.length - 1].getAttribute('uri');
            fetch(url+"/library/sections/"+library+"/refresh/?X-Plex-Token="+token)
            .then((response) => {
                if(response.ok){
                    alert("Refresh of plex library successfully launched");
                }else{
                    alert("Fail to refresh plex library");
                }
            })
        });
    }

    return <button className="btn btn-dark" onClick={refresh}><i className="bi bi-arrow-clockwise"></i> Refresh Plex library</button>
            
            
}
export default PlexRefreshButton