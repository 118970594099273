import React, { useContext } from "react";
import { BrowserContext } from "./services/BrowserContext";

const ContentActionMenu = (props)=>{
    const {itemsManipulator} = useContext(BrowserContext);
    const {handleSelectedItemName} = useContext(BrowserContext);
    const pathlink = encodeURI(props.path.join('/')+'/'+props.name);

    const deleteItem = function (e) {
        if(window.confirm("Are you sure you want to delete "+props.name+"?")){
            itemsManipulator.path = props.path;
            itemsManipulator.name = props.name;
            itemsManipulator.delete();
        }
    }

    const renameItem = function (e) {
        itemsManipulator.path = props.path;
        itemsManipulator.name = props.name;
        handleSelectedItemName(props.name);
    }

    return <div className="dropdown">
        <i className="bi bi-three-dots-vertical" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="menu-4"></i>
        <ul aria-labelledby="menu-4" className="dropdown-menu">
            <li>
                <a href={process.env.REACT_APP_API_URL+"?action=download&path="+pathlink} target="_blank" rel="noopener noreferrer" className="dropdown-item">Download</a>
                <div onClick={deleteItem} className="dropdown-item">Delete</div>
                <div onClick={renameItem} className="dropdown-item" data-bs-toggle="modal" data-bs-target="#renameFolderModal">Rename</div>
            </li>
        </ul>
    </div>
}
export default ContentActionMenu