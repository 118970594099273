import React, { useContext } from "react";
import { BrowserContext } from "./services/BrowserContext";
import ContentActionMenu from "./ContentActionMenu";

const File = (props)=>{
    const {itemsManipulator} = useContext(BrowserContext);

    const download = function() {
        itemsManipulator.name = props.name;
        itemsManipulator.path = props.path;
        itemsManipulator.download();
    }
   
    const size = Math.ceil(props.size/1024).toLocaleString();
    return <button className="list-group-item list-group-item-action justify-content-between d-flex align-items-center" onDoubleClick={download}>
        <div className="col-auto">
            <i className="bi bi-file-text text-dark"></i>
        </div>
        <div className="row col align-items-center">
            <div className="col-md-8 col-lg-9 col-xl-10 text-break">
                <div>{props.name}</div>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-2 ">
                {size} ko
            </div>
        </div>
        <div className="col-auto d-flex justify-content-end">
            <ContentActionMenu name={props.name} path={props.path}/>
        </div>
        
    </button>
}
export default File