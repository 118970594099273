import React, { useContext, useId } from "react";
import styles from "./folderTree.module.css";
import { BrowserContext } from "./services/BrowserContext";

const FolderTree = React.memo((props)=>{
    const {handleCurrentPath} = useContext(BrowserContext);
    let path = Array.from(props.parrentPath);
    path.push(props.name);
    const childFolder = props.child.find((folder) => folder.type === 'folder');
    let id = useId();
    return <li className={styles.li}>
                <div className={styles.div}>
                    {
                        childFolder ? 
                        <button className={styles.button} data-bs-toggle="collapse" data-bs-target={"#"+id} aria-expanded="false"><img alt="toggle" src="/assets/ico/toggle.svg"/></button> : 
                        <div className={styles.emptyFolder}></div>
                    }
                    <div className='d-flex align-items-center flex-fill' onClick={(e)=> handleCurrentPath(path)}>
                        <i className="bi bi-folder-fill text-warning"></i>
                        <p className={styles.folderName}>{props.name}</p>
                    </div>
                    
                </div>
                {
                    childFolder ? 
                    <ul className={styles.ul + " collapse"} id={id}>
                        {
                            props.child.map(folder => folder.type === 'folder'  ? <FolderTree parrentPath={path} key={path.join('/')+'/'+folder.name} name={folder.name} child={folder.child}/> : null)
                        }
                    </ul> : ''
                }
            </li>
})
export default FolderTree