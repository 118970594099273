import React from "react";

const FileProgress = ({fileInfo})=>{
    if(fileInfo && fileInfo.loaded !== 100){
        return <div className="container-fluid rounded border bg-white p-3">
                    <div className="text-center">
                        <span className="name">{fileInfo.name}</span>
                        <span>&nbsp;</span>
                        <span className="percent">{fileInfo.size}</span>
                    </div>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{width: fileInfo.loaded + '%'}} aria-valuenow={fileInfo.loaded} aria-valuemin="0" aria-valuemax="100">{fileInfo.loaded}%</div>
                    </div>
            </div>
    }
    return null;
}
export default FileProgress