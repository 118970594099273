class Uploader{

    constructor() {
        this.files = [];
        this.tempFiles = [];
        this.totalTempFiles = 0;
        this.processedTempFiles = 0;
        this.handleFileInfo = null;
        this.handleHistorical = null;
        this.itemsManipulator = null;
        this.inProgress = false;
        this.chunkSize = 1024 * 1024 * 200; // 200MB chunks
        this.fileLoaded = 0;

    }

    splitFile(file){
        file.totalChunks = Math.ceil(file.size / this.chunkSize);
        const tempFile = this.fileExist({name : file.name+".tmp", path : file.path})
        let start = 0;
        let chunkIndex = 0;
        if(tempFile){
            chunkIndex = tempFile.size / this.chunkSize;
            if(Number.isInteger(chunkIndex)){
                start = tempFile.size;
            }else{
                chunkIndex = 0;
            }
        }
        while(chunkIndex !== file.totalChunks){
            const end = Math.min(start + this.chunkSize, file.size);
            const chunk = file.slice(start, end);
            chunk.chunkIndex = chunkIndex;
            chunk.path = file.path;
            chunk.currentPath = file.currentPath;
            chunk.name=file.name+".part"+chunkIndex;
            chunk.totalChunks = file.totalChunks;
            chunk.initialSize = file.size;
            this.tempFiles.push(chunk) ;
            start = end;
            chunkIndex++;
        }
    }

    fileExist(file){
        let fileFolder = this.itemsManipulator.elements;
        file.path.every( folderName => {
            fileFolder = fileFolder.child.find( element => element.name === folderName && element.type === 'folder');
            return fileFolder;
        });
        if(fileFolder){
            return fileFolder.child.find( element => element.name === file.name && element.type === 'file')
        }
        return false;
    }

    scanFiles(item,path) {
        if (item.isFile) {
            let currentPath = [];
            item.file(file => {
                this.processFiles(file,path,currentPath);
                this.checkFiles();
            });
        }
        if (item.isDirectory) {
            let newPath = Array.from(path);
            newPath.push(item.name);
            let directoryReader = item.createReader();
            let uploader = this;
            directoryReader.readEntries(function(entries) {
                uploader.totalTempFiles += entries.length;
                entries.forEach(function(entry) {
                    uploader.scanFiles(entry,newPath);
                });
                uploader.totalTempFiles--;
                uploader.checkFiles();
            });
        }
    }

    processFiles(file,path,currentPath){
        file.path = path;
        file.existing = this.fileExist(file);
        file.currentPath = currentPath;
        file.chunkIndex = 0;
        file.totalChunks = 0;
        if(file.size > this.chunkSize){
            this.splitFile(file);
        }else{
            this.tempFiles.push(file);
        }
        this.processedTempFiles++;
    }
    
    dropFiles(event,currentPath){
        event.preventDefault();

        let items = event.dataTransfer.items;
        this.totalTempFiles = items.length;
    
        for (let i=0; i<items.length; i++) {
            let item = items[i].webkitGetAsEntry();
    
            if (item) {
                this.scanFiles(item,currentPath);
            }
        }
        this.checkFiles();
    }

    addUploadFiles(fileList,currentPath=[],path=[]){
        this.totalTempFiles = [...fileList].length;
        [...fileList].forEach( file => {
            this.processFiles(file,path,currentPath);
        });
        this.checkFiles();
    }

    checkFiles(){
        if(this.processedTempFiles === this.totalTempFiles){
            this.processedTempFiles = 0;
            const existingFiles = this.tempFiles.filter((file) => file.existing === true);

            if(existingFiles.length > 0 && !window.confirm(existingFiles.length + " files already exist overright ?")){
                this.files.push(...this.tempFiles.filter((file) => file.existing === false));
                const force = false;
            }else{
                this.files.push(...this.tempFiles);
                const force = true;
            }
            
            this.tempFiles = [];

            if(!this.inProgress && this.files.length > 0){
                this.uploadFile();
            }
        }
    }

    uploadFile(force){
        this.inProgress = true;
        let file = this.files.shift();
        let xhr = new XMLHttpRequest(); //creating new xhr object (AJAX)
        let fileSize;
        xhr.open("POST", process.env.REACT_APP_API_URL); //sending post request to the specified URL
        xhr.upload.addEventListener("progress", ({loaded, total}) =>{ //file uploading progress event
            if(file.totalChunks > 0 ){
                total = file.initialSize;
                loaded = this.fileLoaded + loaded;
            }
            let fileLoaded = Math.floor((loaded / total) * 100);  //getting percentage of loaded file size
            let fileTotal = Math.floor(total / 1000); //gettting total file size in KB from bytes
            // if file size is less than 1024 then add only KB else convert this KB into MB
            (fileTotal < 1024) ? fileSize = fileTotal + " KB" : fileSize = (loaded / (1024*1024)).toFixed(2) + " MB";
            this.handleFileInfo({'name': file.name, 'size' : fileSize, 'loaded' : fileLoaded});
        });
        const uploader = this;
        xhr.addEventListener("error", event => {
            alert("Error during transfert of "+file.name);
            uploader.handleFileInfo({'name': file.name, 'size' : fileSize, 'loaded' : 100});
        });
        const itemsManipulator = this.itemsManipulator;
        xhr.onload = function(event) {
            if (xhr.status === 200) {
                if(file.totalChunks > 0 && file.chunkIndex !== file.totalChunks - 1){
                    uploader.fileLoaded += file.size;
                }else{
                    uploader.fileLoaded = 0;
                }
                
                if(file.totalChunks === 0 || file.chunkIndex === file.totalChunks - 1){
                    uploader.handleFileInfo({'name': file.name, 'size' : fileSize, 'loaded' : 100});
                }
                let data = JSON.parse(xhr.responseText);
                if(data.status === 'ok'){
                    uploader.handleHistorical(file.name + " : Uploaded!");
                    itemsManipulator.handleContent(data.content,file.currentPath);
                    itemsManipulator.handleCurrentPath(file.currentPath);
                }
                if(data.status === 'nok'){
                    uploader.handleHistorical(file.name + " : Failed! " + data.content);
                }
                if(uploader.files.length > 0){
                    uploader.uploadFile();
                }
                else{
                    uploader.inProgress = false;
                }
    
            } else {
                uploader.handleFileInfo({'name': file.name, 'size' : fileSize, 'loaded' : 100});
            }
        }

        let form_data = new FormData(); //FormData is an object to easily send form data
        form_data.append('file[]', file,file.name);
        form_data.append('path', file.path.join('/'));
        form_data.append('currentPath', file.currentPath.join('/'));
        form_data.append('chunkIndex', file.chunkIndex);
        form_data.append('totalChunks', file.totalChunks);
        form_data.append('action', 'upload');
        if(this.itemsManipulator.session_id !== null){
            form_data.append('session_id', this.itemsManipulator.session_id);
        }
        xhr.send(form_data); //sending form data
        //console.log(currentPath+path);
    }
}

export default Uploader