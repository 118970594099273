import React, { useContext } from "react";
import { BrowserContext } from "../services/BrowserContext";

const NewFolderModal = (props) => {
    const {itemsManipulator} = useContext(BrowserContext);
    let name = '';

    const onChange = function (e) {
        name = e.target.value;
    }

    const save = () => {
        itemsManipulator.name = name;
        itemsManipulator.path = props.path;
        itemsManipulator.create();
    }

    return  (
        <div className="modal fade" id="newFolderModal" tabIndex="-1" aria-labelledby="modal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalTitle">New Folder</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body" id="modalBody">
                        <input onChange={onChange} type="text" className="form-control"></input>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button onClick={save} type="button" data-bs-dismiss="modal" className="btn btn-primary" id="modalSave">Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
        
}
export default NewFolderModal