import {Modal} from "bootstrap";
class ItemsManipulator {
    constructor() {
        this.elements = {};
        this.path=[];
        this.name="";
        this.handleCurrentPath = null;
        this.handleUser = null;
        this.session_id = null;
        this.loginModal = null;
    }
    login(username,password){
        this.send('login',[username,password]);
    }
    logout(){
        this.send('logout');
    }
    download() {
        const pathlink = encodeURI(this.path.join('/')+'/'+this.name);
        var a = document.createElement("a");
        a.href = process.env.REACT_APP_API_URL+"?action=download&path="+pathlink+"&session_id="+this.session_id;
        a.target ="_blank";
        a.rel="noopener noreferrer";
        a.setAttribute("download", this.name);
        a.click();
    }
    create(){
        this.send('create');
    }
    rename(newName){
        this.send('rename',newName);
    }
    delete(){
        this.send('delete');
    }
    get(){
        this.send('get');
    }
    getUser(){
        this.send('getUser');
    }
    handleContent(content,path){
        let currentFolder = this.elements;
        path.forEach(folderName => currentFolder = currentFolder.child.find( element => element.name === folderName && element.type === 'folder'));
        currentFolder.child = content;
    }
    send(action,params = null){
        var pathUrl = this.path.join('/')+'/'+this.name;
        if(this.path == []){
            pathUrl = this.name;
        }
        const itemsManipulator = this;
      
        var request = new XMLHttpRequest();
        var url = process.env.REACT_APP_API_URL;
        
        request.open('POST', url, true);
      
        request.onload = function() {
          if (request.status >= 200 && request.status < 400) {
            // Success!
            var data = JSON.parse(request.responseText);
            if(data.status === "ok" || data.status === "login"){
                itemsManipulator.handleContent(data.content,[]);
                itemsManipulator.handleCurrentPath(itemsManipulator.path);
            }
            if(data.status === "expired"){
                itemsManipulator.loginModal.show();
            }
            if(data.status === "login"){
                itemsManipulator.session_id = data.session_id;
                itemsManipulator.handleUser(data.user);
            }
            if(data.status === "user"){
                itemsManipulator.handleUser(data.user);
            }
            if(data.status === "nok"){
                window.alert(data.content);
            }
            
          } else {
            alert('server error :'+request.responseText);
          }
        };
      
        request.onerror = function() {
          alert('connection error');
        };

        let form_data = new FormData(); //FormData is an object to easily send form data
        if(action === 'get'){
            form_data.append('path', '/');
        }else{
            form_data.append('path', pathUrl);
        }
        form_data.append('action', action);
        if(action === 'rename'){
            if(params === null){
                return "New name cannot be null";
            }
            //url += '&newName='+encodeURIComponent(params);
            form_data.append('newName', params);
        }
        if(action === 'login'){
            form_data.append('username', params[0]);
            form_data.append('password', params[1]);
        }
        if(this.session_id !== null){
            form_data.append('session_id', this.session_id);
        }
        request.send(form_data);
        
    }
}

export default ItemsManipulator