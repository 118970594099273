import React, { useContext } from "react";
import { BrowserContext } from "./services/BrowserContext";

const Profile = ({user,handleUser})=>{
    const {itemsManipulator,handleContent} = useContext(BrowserContext);

    const logout = function (e) {
        itemsManipulator.logout();
        handleContent([],[]);
        handleUser('');
    }

    if(!user){
        return <button className="btn btn-secondary" type="button" data-bs-toggle="modal" data-bs-target="#loginModal">
                    login
                </button>
    }

    return <div className="dropdown">
        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            {user.name}
        </button>
        <ul aria-labelledby="menu-4" className="dropdown-menu dropdown-menu-end">
            <li>
                <button onClick={logout} className="dropdown-item">Logout</button>
            </li>
        </ul>
    </div>
}
export default Profile