import { createContext } from 'react'
import ItemsManipulator from "./../services/ItemsManipulator";

export const BrowserContext = createContext({
    handleCurrentPath : () => {},
    handleContent : () => {},
    handleSelectedItemName : () => {},
    itemsManipulator : new ItemsManipulator()
})

/* const BrowserProvider = (children) => {
    const [currentFolderContent,setContent] = useState([]) ;
    const [currentPath,setPath] = useState([]) ;
    const [selectedItemName,setselectedItemName] = useState('');
    
    const elements = {'name' : 'Root', 'type' : 'folder', 'child' :[]};

    const handleSelectedItemName = (name) => setselectedItemName(name);

    const handleCurrentPath = (path) => setPath(path);

    const handleContent = (content,path) => {
        let currentFolder = elements;
        path.forEach(folderName => currentFolder = currentFolder.child.find( element => element.name === folderName && element.type === 'folder'));
        currentFolder.child = content;
        setContent(content);
    }

    let itemsManipulator = new ItemsManipulator();

    return (
        <BrowserContext.Provider value={{currentPath, handleCurrentPath,handleContent,handleSelectedItemName, selectedItemName,itemsManipulator}}>
            {children}
        </BrowserContext.Provider>
    )
} */